// ** Redux Imports
import { combineReducers } from 'redux'
import chat from '@src/views/apps/chat/store/reducer'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import loaderReducer from "./loader";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  chat,
  loader: loaderReducer
})

export default rootReducer
